const resource = {
    en: {
        success: {
            heading: 'You have been verified',
            message: 'You were successfully verified and can now access all features of Planungsmatrix. Just login and start.',
            home_button: 'Login'
        },
        failure: {
            heading: 'Verification failed',
            message: 'Please consult a member of the Planungsmatrix Team.',
            home_button: 'Back to Home'
        }
    },
    de: {
        success: {
            heading: 'Ihr Account wurde verifiziert',
            message: 'Sie können sich nun einloggen und alle Funktionen von der Planungsmatrix verwenden.',
            home_button: 'Back to Home'
        },
        failure: {
            heading: 'Verifizierung fehlgeschlagen',
            message: 'Bitte wenden Sie sich an das Planungsmatrix-Team.',
            home_button: 'Anmelden'
        }
    }
}

export default resource;