<template>
    <div class="authentication-card" v-show="success !== null">
        <h1 class="authentication-heading" v-html="resource[lang][success ? 'success' : 'failure'].heading" />
        <p class="authentication-text" v-html="resource[lang][success ? 'success' : 'failure'].message" />
        <Button stretch rounded submit
            class="authentication-button" 
            color="primary" 
            :label="resource[lang][success ? 'success' : 'failure'].home_button" 
            @click="$router.push(success ? '/login': '/')"
        />
    </div>
</template>

<script>
import resource from '@/assets/resources/user-verification-resource'

import Button from '@/components/basic/Button'

import { promisePostRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        Button
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            success: null,

            resource
        }
    },
    computed: {
        token() {
            return this.$route.params.token ? this.$route.params.token : null;
        }
    },
    async created() {
        this.getSuccess();
    },
    methods: {
        async getSuccess() {
            if (this.token) {
                this.$store.dispatch('loading', true);
                const response = await this.verifyUserRequest();
                this.$store.dispatch('loading', false);

                if (response.data.status === 'success' || response.status === 200) {
                    this.success = true;
                } else {
                    this.success = false;
                }
                
            }
        },

        // Request
        verifyUserRequest() {
            const request_body = {
                token: this.token
            }
            return promisePostRequest('verify-user', request_body);
        },
    },
    watch: {
        token: function() {
            this.getSuccess();
        }
    }
}
</script>